/* AdminForm.css */

.form-container {
  padding: 1rem;
  border: 1px solid rgb(229, 231, 235);
  width: 40%;
  }
  
  .form-input input {
    width: 100%;
    padding: 0.5rem;
    border-radius: .5rem;
    color: var(--heading-color);
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid rgb(229, 231, 235);
  }
  .form-input input:focus{
    outline: none;
  }
  
  .form-label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .form-submit-btn {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background: var(--heading-color) !important;
    color: #fff !important;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .form-submit-btn:hover {
    background-color: #0062cc;
  }

  .btn1 btn primary__btn mt-4{
    margin-right: 20px;
    margin-left: 10px;
    height: 30px; /* set the height of the button to 30 pixels */
    font-size: 14px;
    cursor: pointer;
    width: 100px; /* set the width of the button to 100 pixels */
  overflow: hidden; /* hide any overflowing text */
  white-space: nowrap; /* prevent text from wrapping to the next line */
  text-overflow: ellipsis;
  }

  .tour-list {
    list-style: none;
    padding: 0;
  }
  
  .tour-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .tour-title {
    flex-grow: 1;
    margin-right: 10px;
  }
  
  @media only screen and (max-width:768px){
    .form-container{
      padding-bottom: 3rem;
        margin-top: 70px;
    }
    .tour-item{
      flex-direction: column;
    align-items: flex-start;
    }
  }

  @media screen and (max-width: 600px) {
    .form-input input {
      font-size: 0.8rem;
      padding: 0.3rem;
    }
  }
  
  /* Media Query for screens between 600px and 900px */
  @media screen and (min-width: 600px) and (max-width: 900px) {
    .form-input input {
      font-size: 0.9rem;
      padding: 0.4rem;
    }
  }
  
  /* Media Query for screens larger than 900px */
  @media screen and (min-width: 900px) {
    .form-input input {
      font-size: 1rem;
      padding: 0.5rem;
    }
  }
  
  
  
  
  
  
  